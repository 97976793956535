import styled from 'styled-components'
import { TextField } from '@mui/material'

export const FormGroup = styled.div`
  margin-bottom: ${props => (props.mb ? props.mb : '0')};
`

export const TextInput = styled(TextField)`
  display: block !important;
  /* padding: 0.375rem 0.75rem !important; */
  font-size: 1rem;
  line-height: 1.5;
  color: ${props => props.textcolor};
  background: ${props => props.bg};
  background-clip: padding-box;
  /* border: 1px solid #eaeaea; */
  /* border: ${props => props.border}; */
  border-radius: ${props => (props.borderradius ? props.borderradius : '25px')};

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 900 !important;
    // box-shadow: inset 0px 2px 2px 1px rgba(0, 0, 0, 0.05)
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
  . MuiTextField-root {
  }
  .MuiOutlinedInput-notchedOutline {
    border: ${props => (props.border ? props.border : 'unset')}!important;
    border-bottom: ${props => (props.borderbottom ? props.borderbottom : 'unset')}!important;
    border-radius: ${props =>
      props.borderradius ? props.borderradius : '25px'};
  }
  .MuiInputBase-input-MuiOutlinedInput-input {
    color: #000;
  }
  .MuiOutlinedInput-input {
    border: ${props => (props.border ? props.border : 'unset')}!important;
    color: ${props => props.textcolor} !important;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : '400')};
    padding: ${props =>
      props.padding ? props.padding : '16px 27px !important'};
    font-size: ${props => (props.size ? props.size : '1rem')};
    border-radius: ${props =>
      props.borderradius ? props.borderradius : '25px'};
    font-family: ${props =>
      props.fontfamily ? props.fontfamily : "'Helvetica', sans-serif"};
  }
  input::placeholder {
    font-weight: ${props => (props.fontWeight ? props.fontWeight : '400')};
    opacity: 1;
    color: ${props => props.textcolor};
  }
  .MuiSelect-icon {
    color: ${props => props.textcolor};
  }
  .Mui-disabled {
    -webkit-text-fill-color: ${props => props.textcolor} !important;
  }
`
