import styled from 'styled-components'
import { Block, Menu, Search } from '@mui/icons-material'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { md } from '../../styles/responsive'

const config = {
  theFooter: {
    backgroundColor: 'var(--background-color-1)',
  },
  theNav: {
    padding: '46px 15px'
  },
  theLogo: {
    width: '200px',
    margin: '0 auto',
    '& img': {
      width: '100%',
      display: 'block'
    }
  },
  theDivider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#DBDBDB'
  },
  theNavLink: {
    padding: '0 0 14px',
    cursor: 'pointer'
  },
  theCopyRight: {
    // margin: "40px 0 0",
    padding: '18px 15px',
    backgroundColor: 'var(--background-color-1)',
  },
  theNavBar: {
    background: '#fff',
    height: '70px',
    position: 'fixed',
    bottom: '0',
    maxWidth: '400px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0.1vw 0vw 1.6vw -0.8vw rgba(0,0,0,.15)'
  },
  theNBCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20%'
  },
  theIconNav: {
    width: '30px',
    aspectRatio: 1,
    margin: '0 0 4px',
    '& img': {
      width: '100%',
      aspectRatio: 1,
      display: 'block'
    }
  },
  theSticky: {
    position: 'fixed',
    width: '100%',
    left: '0',
    top: '0',
    zIndex: '3'
  },
  customDrawer: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    right: '0px',
    top: '0px',
    height: '800vh',
    width: '100%'
    // opacity: '0',
    // transition: 'opacity 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    // visibility: "hidden",
  },
  customDrawerShow: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    right: '0px',
    top: '0px',
    height: '800vh',
    width: '100%',
    // transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    // visibility: "visible",
    zIndex: 9999
  },
  customDrawerContentRelative: {
    position: 'relative',
    overflow: 'hidden',
    height: '100vh',
    width: '100%',
    margin: '0 0 0 auto'
  },
  customDrawerContent: {
    position: 'absolute',
    backgroundColor: 'var(--accent-color)',
    margin: '0 0 0 auto',
    height: 'auto',
    width: '100%',
    right: '0',
    bottom: '0',
    // overflowY: 'scroll',
    // transform: 'translateX(-100%)',
    transition: 'transform 0.3s ease-in-out'
  },
  customDrawerContentHide: {
    position: 'absolute',
    backgroundColor: 'var(--accent-color)',
    margin: '0 0 0 auto',
    height: '100vh',
    width: '75%',
    right: '-75%',
    // overflowY: 'scroll',
    transform: 'translateY(100%)',
    transition: 'transform 0.3s ease-in-out'
  },
  theNBUser: {
    width: '100%',
    padding: '12px 16px'
  },
  theNBFlexWrap: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '12px 0 12px'
  },
  theNBFWCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20%',
    padding: '6px 0px'
  },
  theFWIconNav: {
    width: '30px',
    aspectRatio: 1,
    margin: '0 0 4px',
    '& img': {
      width: '100%',
      aspectRatio: 1,
      display: 'block'
    }
  }
}
export default makeStyles(config)
