import styled from 'styled-components'
import { makeStyles } from '@mui/styles'
import { md } from '../../styles/responsive'
import { FormControl, Input } from '@mui/material'
import { width } from '@mui/system'
import { BorderBottom } from '@mui/icons-material'

const config = {
  theOrderDetail: {
    marginTop: '100px'
  },
  theODContent: {
    minHeight: '100vh',
    position: 'relative',
    padding: '0 15px'
  },
  theODHeader: {
    padding: '40px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  theODProgress: {
    position: 'relative'
  },
  theODPTextBox: {
    width: '150px',
    height: '150px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  theODTextFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 15px',
    margin: '0 0 20px'
  },
  theODTFLeft: {
    width: '48%'
  },
  theODTFRight: {
    width: '48%'
  },
  theButton: {
    padding: '0 15px'
  }
}

export default makeStyles(config)
