import React from "react";
import { Routes as Router, Route } from "react-router-dom";
import Loader from "react-js-loader";
import ProcessingOrderDetail from "./pages/ProcessingOrderDetail/ProcessingOrderDetail";

const LoginPage = React.lazy(() => import("./pages/Login/Login"));
const RegisterPage = React.lazy(() => import("./pages/Register/Register"));
const HomePage = React.lazy(() => import("./pages/Home/NewHome"));
const MarketPage = React.lazy(() => import("./pages/Market/Market"));
const RechargePage = React.lazy(() => import("./pages/Deposit/Deposit"));
const WithdrawPage = React.lazy(() => import("./pages/Withdraw/Withdraw"));
const MainPage = React.lazy(() => import("./pages/Main/Main"));
const ExplorePage = React.lazy(() => import("./pages/Explore/Explore"));
const WalletPage = React.lazy(() => import("./pages/Wallet/Wallet"));
const OrderPage = React.lazy(() => import("./pages/Order/Order"));
const ProfilePage = React.lazy(() => import("./pages/Profile/Profile"));
const HavingTroublePage = React.lazy(() =>
  import("./pages/HavingTrouble/HavingTrouble")
);
const ResetPasswordPage = React.lazy(() =>
  import("./pages/ResetPassword/ResetPassword")
);
const ResetPasswordSuccessPage = React.lazy(() =>
  import("./pages/ResetPasswordSuccess/ResetPasswordSuccess")
);
const ResetPinSuccessPage = React.lazy(() =>
  import("./pages/ResetPinSuccess/ResetPinSuccess")
);
const ResetPinPage = React.lazy(() => import("./pages/ResetPin/ResetPin"));
const WithdrawMethodPage = React.lazy(() =>
  import("./pages/WithdrawMethod/WithdrawMethod")
);
const TransactionHistoryPage = React.lazy(() =>
  import("./pages/TransactionHistory/TransactionHistory")
);
const OrderHistoryPage = React.lazy(() =>
  import("./pages/OrderHistory/OrderHistory")
);
const StatisticPage = React.lazy(() => import("./pages/Statistic/Statistic"));
// const CoinDetailPage = React.lazy(() =>
//   import("./pages/CoinDetail/CoinDetail")
// );
const CoinDetailPage = React.lazy(() =>
  import("./pages/CoinDetail/NewCoinDetails")
);
const ProcessingOrderDetailPage = React.lazy(() =>
  import("./pages/ProcessingOrderDetail/ProcessingOrderDetail")
);
const CompleteOrderDetailPage = React.lazy(() =>
  import("./pages/CompleteOrderDetail/CompleteOrderDetail")
);

const UpdateProfilePage = React.lazy(() =>
  import("./pages/Profile/UpdateProfile/UpdateProfile")
);

const HelpPage = React.lazy(() => import("./pages/Help/Help"));
const ProfitPage = React.lazy(() => import("./pages/Profit/Profit"));
export default function Routes() {
  return (
    <React.Suspense
      fallback={
        <div
          style={{
            display: "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Loader type='bubble-top' bgColor={'#3490cc'} size={30} /> */}
        </div>
      }
    >
      <Router>
        {/* <Route path="/" element={<MainPage />} /> */}
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/main" element={<MainPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/home" element={<HomePage />} />
        {/* <Route path="/market" element={<MarketPage />} /> */}
        <Route path="/recharge" element={<RechargePage />} />
        <Route path="/withdraw" element={<WithdrawPage />} />
        <Route path="/withdraw-method" element={<WithdrawMethodPage />} />
        <Route path="/capital-flow" element={<TransactionHistoryPage />} />
        <Route path="/order-history" element={<OrderHistoryPage />} />
        {/* <Route path="/statistics" element={<StatisticPage />} /> */}
        <Route path="/having-trouble" element={<HavingTroublePage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/reset-pin" element={<ResetPinPage />} />
        <Route path="/explore" element={<ExplorePage />} />
        {/* <Route path="/wallet" element={<WalletPage />} />
        <Route path="/orders" element={<OrderPage />} /> */}
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/update-profile" element={<UpdateProfilePage />} />
        <Route path="/help" element={<HelpPage />} />
        <Route path="/profit" element={<ProfitPage />} />
        <Route
          path="/reset-password-success"
          element={<ResetPasswordSuccessPage />}
        />
        <Route path="/reset-pin-success" element={<ResetPinSuccessPage />} />
        {/* <Route
          path="/coin/:coin_symbol/:coin_id"
          element={<CoinDetailPage />}
        /> */}
        <Route
          path="/coin/:coin_symbol/:coin_id"
          element={<CoinDetailPage />}
        />
        <Route
          path="/processing-order/:user_order_id"
          element={<ProcessingOrderDetailPage />}
        />
        <Route
          path="/complete-order/:user_order_id"
          element={<CompleteOrderDetailPage />}
        />

        {/* <Route path="/membership" element={<MemberShipPage />} />
        <Route path="/referral-program" element={<ReferralProgramPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/book-now" element={<BookNowPage />} />
        <Route path="/withdraw" element={<WithdrawPage />} />
        <Route path="/deposit" element={<DepositPage />} />
        <Route path="/history" element={<HistoryPage />} />
        <Route path="/transaction-history" element={<TransactionHistoryPage />} />
        <Route path="/setting" element={<SettingPage />} /> */}

        {/* <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/setting" element={<SettingPage />} />
        <Route path="/kiosk" element={<KioskPage />} />
        <Route path="/reception-counter-table" element={<ReceptionCounterPage />} /> */}
        {/* <Route path="/reception-member-profile/:appointment_id" element={<RCMemberProfilePage />} /> */}
      </Router>
    </React.Suspense>
  );
}
