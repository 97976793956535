import axios from "axios";

const host = "https://eportalaccess.lol/";
// const host = 'https://yellowdot.digital/whitebit/admin/'
const access_endpoint_link = host + "Axios/";

const format_request = (postparam) => {
  let lang = 0;
  let lang_res = window.localStorage.getItem("language");
  if (lang_res) {
    lang = lang_res;
    // if (lang_res == 1) {
    //   lang = 1
    // } else {
    //   lang = 2
    // }
  }
  if (!postparam["lang"]) {
    postparam["lang"] = lang;
  }
  let token = 0;
  let token_res = window.localStorage.getItem("token");
  // let token_res = "008fb26450c4dacd84a5fd5034f994b5"
  if (token_res) {
    token = token_res;
  }
  if (!postparam["token"]) {
    postparam["token"] = token;
  }

  let body = "";
  for (let k in postparam) {
    body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
  }
  var postOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body,
  };
  return postOptions;
};

const api_post = async (postparam = {}, end_point) => {
  var response = await fetch(
    access_endpoint_link + end_point,
    format_request(postparam)
  );

  var json = await response.json();
  if (json.status) {
    return json;
  } else {
    // if (json.message == "Please login again") {
    //     window.localStorage.clear();
    //     //alert(json.message);
    //     window.location.href = "/login";
    //     return json;
    // } else {
    //     //   alert(json.message);
    // }
    return json;
  }
};

export const formatNewJson = (filter, origininalState) => {
  filter.map((row, index) => delete origininalState[row]);
  return origininalState;
};

export const login = async (postparam) => {
  return api_post(postparam, "login");
};

export const register = async (postparam) => {
  return api_post(postparam, "register");
};

export const get_user_info = async (postparam) => {
  return api_post(postparam, "get_user_info");
};

export const get_banner = async (postparam) => {
  return api_post(postparam, "get_banner");
};

export const get_coin = async (postparam) => {
  return api_post(postparam, "get_coin");
};

export const get_coin_detail = async (postparam) => {
  return api_post(postparam, "get_coin_detail");
};

export const get_package = async (postparam) => {
  return api_post(postparam, "get_package");
};

export const submit_order = async (postparam) => {
  return api_post(postparam, "submit_order");
};

export const get_order_result = async (postparam) => {
  return api_post(postparam, "get_order_result");
};

export const check_exist_contact_post = async (postparam) => {
  return api_post(postparam, "check_exist_contact_post");
};

export const send_otp = async (postparam) => {
  return api_post(postparam, "send_otp");
};

export const verify_otp = async (postparam) => {
  return api_post(postparam, "verify_otp");
};

export const get_shop_data_post = async (postparam) => {
  return api_post(postparam, "get_shop_data_post");
};

export const topup_wallet = async (postparam) => {
  return api_post(postparam, "topup_wallet");
};

export const withdraw_wallet = async (postparam) => {
  return api_post(postparam, "withdraw_wallet");
};

export const get_user_payment_method = async (postparam) => {
  return api_post(postparam, "get_user_payment_method");
};

export const get_payment_method = async (postparam) => {
  return api_post(postparam, "get_payment_method");
};

export const update_payment_method = async (postparam) => {
  return api_post(postparam, "update_payment_method");
};

export const get_wallet_history = async (postparam) => {
  return api_post(postparam, "get_wallet_history");
};

export const get_investing_order = async (postparam) => {
  return api_post(postparam, "get_investing_order");
};

export const get_closed_order = async (postparam) => {
  return api_post(postparam, "get_closed_order");
};

export const filter_own_data = async (postparam) => {
  return api_post(postparam, "filter_own_data");
};

export const get_faq = async (postparam) => {
  return api_post(postparam, "get_faq");
};

export const add_user_bank = async (postparam) => {
  return api_post(postparam, "add_user_bank");
};

export const delete_payment_method = async (postparam) => {
  return api_post(postparam, "delete_payment_method");
};

export const filter_own_data_v2 = async (postparam) => {
  return api_post(postparam, "filter_own_data_v2");
};

export const reset_password = async (postparam) => {
  return api_post(postparam, "reset_password");
};

export const verify_password = async (postparam) => {
  return api_post(postparam, "verify_password");
};

export const edit_secure_pin = async (postparam) => {
  return api_post(postparam, "edit_secure_pin");
};

export const edit_profile = async (postparam) => {
  return api_post(postparam, "edit_profile");
};
// export const numberWithCom mas = (x) => {
//     return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
// }

export const get_upload_path = async (postparam) => {
  return axios.post(access_endpoint_link + "get_upload_path", postparam);
  // let res = await axios.post(
  //   access_endpoint_link + "get_upload_path",
  //   postparam
  // );
  // if (res) {
  //   return res.data;
  // } else {
  //   alert(res.message);
  //   // return false;
  // }
};
