import React, { useEffect, useState, useRef } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { Dialog, TextField } from "@mui/material";
import { createChart, ColorType } from "lightweight-charts";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Slider from "react-slick";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import toast from "react-simple-toasts";
import Loader from "react-js-loader";
import moment from "moment";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Paragraph from "../../components/Typography/Paragraph";
import FormInput from "../../components/FormInput/FormInput";
import Button from "../../components/Button/Button";
import useStyles from "./styles.jsx";
import styled, { keyframes } from "styled-components";
import BackIcon from "../../assets/images/Arrow Left.svg";

import useUser from "../../hooks/useUser";
// import { get_banner } from '../../api/API'
import axios from "axios";
import useLocales from "../../hooks/useLocales";
import { NumericFormat } from "react-number-format";
import {
  get_order_result,
  get_coin_detail,
  get_package,
  submit_order,
} from "../../api/API";

const BorderCircleProgress = styled(CircularProgress)(({ theme }) => ({
  // transform: 'rotate(180deg)',
  borderRadius: 0,
  [`&.${circularProgressClasses.colorPrimary}`]: {
    // backgroundColor: '#F5F5F5'
    backgroundColor: "transparent",
  },
  [`& .${circularProgressClasses.circle}`]: {
    // backgroundColor: '#D6E9DF'
    color: "var(--primary-color-ui)",
    strokeWidth: 1,
    transform: "rotate(-90deg) scaleX(-1)",
  },
}));
export default function ProcessingOrderDetail(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const { set_user, get_user } = useUser();
  const { allLang, currentLang, translate, onChangeLang } = useLocales();

  //
  const investmentAmountFiled = {
    name: "order_price",
    placeholder: translate("Enter Investment Amount"),
    className: "theForm",
  };
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState("");
  const [initialTime, setInitialTime] = useState(0);
  const [foreCast, setForeCast] = useState(0);
  const [range, setRange] = useState("1min");
  const [orderDetail, setOrderDetail] = useState(null);
  const [latestData, setLatestData] = useState({
    close: "",
    open: "",
    low: "",
    high: "",
    vol: "",
    amount: "",
  });

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    const num = Math.random() * (max - min) + min;
    return Math.round(num * 100) / 100;
  }

  const handleGetData = async () => {
    var resCoin = await get_order_result({
      user_order_id: params.user_order_id,
    });
    if (resCoin.status) {
      setOrderDetail(resCoin.data);
      setTimeLeft(resCoin.data.time_left);
      setInitialTime(parseInt(resCoin.data.package_name));

      const isLost = Math.round(Math.random()); // Gives either 0 or 1
      const forecastValue = isLost
        ? resCoin.data.lost_amount
        : resCoin.data.win_amount;

      setForeCast(forecastValue);
    }
  };
  useEffect(() => {
    if (timeLeft !== "") {
      if (timeLeft > 0) {
        const timerId = setTimeout(() => {
          setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
        }, 1000);
        return () => clearTimeout(timerId); // Clear the timer when component unmounts or timeLeft changes
      } else if (timeLeft <= 0) {
        navigate("../complete-order/" + params.user_order_id);
      }
    }
  }, [timeLeft]);

  useEffect(() => {
    handleGetData(); // Call immediately upon mount

    const intervalId = setInterval(() => {
      handleGetData();
    }, 3000); // 3000ms equals 3 seconds

    return () => clearInterval(intervalId); // Clear the interval when the component is unmounted
  }, []);

  useEffect(() => {
    if (!orderDetail) {
      return;
    }
    const fetchData = () => {
      const symbol = orderDetail.coin_symbol;

      if (symbol !== "") {
        const url = `https://api.huobi.pro/market/history/kline?symbol=${symbol}&period=${range}&size=100`;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            const modifiedData = data.data.map((item) => {
              return {
                ...item,
                change: Math.random() * 10 - 5,
              };
            });
            setLatestData(modifiedData[0]);
          })
          .catch((error) => console.error(error));
      }
    };

    fetchData(); // fetch immediately
    const intervalId = setInterval(fetchData, 3000); // then fetch every 3 seconds

    return () => clearInterval(intervalId); // cleanup on unmount
  }, [orderDetail, range]);

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {loading ? (
          <div className="customContainer">
            <div
              style={{
                display: "flex",
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader
                type="bubble-loop"
                bgColor={"var(--accent-color-ui)"}
                size={50}
              />
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div style={{ overflow: "hidden" }}>
              {/* {orderDetail != null && ( */}
              <React.Fragment>
                <div>
                  <div className="customContainer">
                    {orderDetail != null && (
                      <div className={classes.theODContent}>
                        <div className={classes.theODHeader} data-aos="fade-up">
                          {/* {latestData.close} */}
                          <div className={classes.theODProgress}>
                            <BorderCircleProgress
                              variant="determinate"
                              size={180}
                              style={{ transform: "rotate(180deg)" }}
                              value={(timeLeft / initialTime) * 100}
                            />
                            <div className={classes.theODPTextBox}>
                              <Paragraph
                                color="var(--accent-color-ui)"
                                bold="500"
                                margin="0 0 5px"
                                size="40px"
                                center="center"
                              >
                                {timeLeft != -1 ? timeLeft : 0}
                              </Paragraph>
                              <Paragraph
                                color="var(--accent-color-ui)"
                                bold="500"
                                margin="0 0 5px"
                                size="14px"
                                center="center"
                              >
                                {translate("Current Price")}
                              </Paragraph>
                              <Paragraph
                                color={
                                  orderDetail.type == 0
                                    ? "var(--color-1)"
                                    : "var(--color-2)"
                                }
                                bold="600"
                                size="16px"
                                center="center"
                              >
                                <NumericFormat
                                  displayType="text"
                                  // value={'100'}
                                  value={latestData.close}
                                  allowLeadingZeros
                                  thousandSeparator=","
                                />
                              </Paragraph>
                            </div>
                          </div>
                        </div>

                        <div className={classes.theODTextFlex}>
                          <div className={classes.theODTFLeft}>
                            <Paragraph
                              color="var(--sub-title-color)"
                              bold="500"
                              size="16px"
                            >
                              {translate("Order Type")}
                            </Paragraph>
                          </div>
                          <div className={classes.theODTFRight}>
                            <Paragraph
                              color={
                                orderDetail.type == 0
                                  ? "var(--color-1)"
                                  : "var(--color-2)"
                              }
                              bold="700"
                              size="16px"
                              center="right"
                            >
                              {orderDetail.type == 0
                                ? translate("Buy")
                                : translate("Sell")}
                            </Paragraph>
                          </div>
                        </div>
                        <div className={classes.theODTextFlex}>
                          <div className={classes.theODTFLeft}>
                            <Paragraph
                              color="var(--sub-title-color)"
                              bold="500"
                              size="16px"
                            >
                              {translate("Investment Amount")}
                            </Paragraph>
                          </div>
                          <div className={classes.theODTFRight}>
                            <Paragraph
                              color="var(--accent-color-ui)"
                              bold="700"
                              size="16px"
                              center="right"
                            >RP{" "}
                              <NumericFormat
                                displayType="text"
                                // value={'100'}
                                value={orderDetail.order_price}
                                allowLeadingZeros
                                thousandSeparator=","
                              />
                            </Paragraph>
                          </div>
                        </div>
                        <div className={classes.theODTextFlex}>
                          <div className={classes.theODTFLeft}>
                            <Paragraph
                              color="var(--sub-title-color)"
                              bold="500"
                              size="16px"
                            >
                              {translate("Profit Percentage")}
                            </Paragraph>
                          </div>
                          <div className={classes.theODTFRight}>
                            <Paragraph
                              color="var(--accent-color-ui)"
                              bold="700"
                              size="16px"
                              center="right"
                            >
                              {orderDetail.com_percentage}%
                            </Paragraph>
                          </div>
                        </div>
                        <div
                          className={classes.theODTextFlex}
                          style={{
                            marginBottom: "30px",
                          }}
                        >
                          <div className={classes.theODTFLeft}>
                            <Paragraph
                              color="var(--sub-title-color)"
                              bold="500"
                              size="16px"
                            >
                              {translate("Forecast Results")}
                            </Paragraph>
                          </div>
                          <div className={classes.theODTFRight}>
                            <Paragraph
                              color="var(--accent-color-ui)"
                              bold="700"
                              size="16px"
                              center="right"
                            >
                              + RP{" "}
                              <NumericFormat
                                displayType="text"
                                // value={'100'}
                                value={orderDetail.percentage_amount}
                                allowLeadingZeros
                                thousandSeparator=","
                              />
                            </Paragraph>
                          </div>
                        </div>

                        <div className={classes.theButton}>
                          <Button
                            onClick={() => navigate("../home")}
                            label={translate("CONTINUE ORDER")}
                            color="var(--title-color)"
                            hvbg="var(--primary-color-ui)"
                            bg="var(--primary-color-ui)"
                            size="14px"
                            bold="700"
                            pd="14px"
                            br="5px"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
              {/* )} */}

              {/* <Footer /> */}
            </div>
          </React.Fragment>
        )}
      </LocalizationProvider>
    </React.Fragment>
  );
}
