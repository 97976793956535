import { makeStyles } from "@mui/styles";
import styled from "styled-components";

export const config = {
  root: {},
};

export const Text = styled.p`
  font-family: ${(props) =>
    props.fontfamily ? props.fontfamily : "'Helvetica', sans-serif"};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  /* font-weight: ${(props) => props.fontWeight}; */
  font-weight: ${(props) => props.bold};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  text-align: ${(props) => (props.center ? props.center : "left")};
  text-decoration: ${(props) => props.textDecoration};
  text-transform: ${(props) => props.texttransform};
  line-height: ${(props) => (props.lineheight ? props.lineheight : "normal")};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "0"};
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.numberofline};
  -webkit-box-orient: vertical;
  overflow: hidden;

  /* &.size-200 {
    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
  }

  &.size-300 {
    @media screen and (min-width: 320px) {
      font-size: 12px;
    }

    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }

  &.size-400 {
    @media screen and (min-width: 768px) {
      font-size: 20px;
    }
  }

  &.size-500 {
    font-size: 1rem;
    @media screen and (min-width: 768px) {
      font-size: 1rem;
    }
    @media screen and (min-width: 992px) {
      font-size: 1.2rem;
    }
  }

  &.size-600 {
    font-size: 1rem;
    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }

    @media screen and (min-width: 992px) {
      font-size: 1.8rem;
    }
  }

  &.size-700 {
    font-size: 1.4rem;
    @media screen and (min-width: 768px) {
      font-size: 2.4rem;
    }
  } */
`;

export default makeStyles(config);
