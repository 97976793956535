import styled, { keyframes } from 'styled-components'
import { makeStyles } from '@mui/styles'
import { md } from '../../styles/responsive'
import { FormControl, Input } from '@mui/material'
import { width } from '@mui/system'

const config = {
  theSticky: {
    position: 'fixed',
    width: '100%',
    left: '0',
    top: '0',
    zIndex: '3'
  },
  theNavBar: {
    backgroundColor: 'var(--background-color-1)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100px',
    padding: '0 15px',
    zIndex: '3'
    // borderBottom: '1px solid #F0F6FF'
  },
  theNavIcon: {
    // width: '20%'
  },
  theLogo: {
    width: '160px',

    ...md('xxs', {
      width: '200px'
    }),
    '& img': {
      width: '100%',
      display: 'block'
    }
  },
  theLang: {
    // width: '20%'
  },
  theIcon20: {
    width: '20px',
    '& img': {
      width: '100%',
      display: 'block'
    }
  },
  theIcon10: {
    width: '10px',
    '& img': {
      width: '100%',
      display: 'block'
    }
  },
  customDrawer: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    left: '0px',
    top: '0px',
    height: '800vh',
    width: '100%',
    // opacity: '0',
    transition: 'opacity 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    // visibility: "hidden",
  },
  customDrawerShow: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    left: '0px',
    top: '0px',
    height: '800vh',
    width: '100%',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    // visibility: "visible",
    zIndex: 9999
  },
  customDrawerContentRelative: {
    position: 'relative',
    overflow: 'hidden',
    height: '100vh',
    width: '100%',
    margin: '0 0 0 auto'
  },
  customDrawerContent: {
    position: 'absolute',
    backgroundColor: 'var(--background-color-1)',
    margin: '0 0 0 auto',
    height: '100vh',
    width: '75%',
    left: '-75%',
    overflowY: 'scroll',
    // overflowX: 'hidden',
    transform: 'translateX(100%)',
    transition: 'transform 0.3s ease-in-out'
  },
  customDrawerContentHide: {
    position: 'absolute',
    backgroundColor: 'var(--background-color-1)',
    margin: '0 0 0 auto',
    height: '100vh',
    width: '75%',
    left: '-75%',
    overflowY: 'scroll',
    // overflowX: 'hidden',
    transform: 'translateX(-100%)',
    transition: 'transform 0.3s ease-in-out'
  },
  theSideBack: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '18px 15px 52px',
    cursor: 'pointer'
  },
  theSideLogin: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0px 15px',
    cursor: 'pointer'
  },
  theSideBalance: {
    backgroundColor: 'var(--background-color-2)',
    borderRadius: '5px',
    padding: '15px 15px'
  },
  theSideUser: {
    padding: '30px 15px 0px'
    // width: '100%'
  },
  theSideUserFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
    // margin: '0 0 15px'
  },
  theSULeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '98%'
  },
  theSULogo: {
    width: '120px',
    '& img': {
      width: '100%',
      display: 'block'
    }
  },
  theSUReferal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    margin: '0 0 15px'
  },
  theSURText: {
    width: '50%'
    // margin: '0 0 0 3px'
  },
  theSURBox: {
    width: '40%',
    height: '20px',
    borderRadius: '20px',
    backgroundColor: 'var(--background-color-2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px'
  },
  theSUReferalText: {
    // width: '50%',
    margin: '0 7px 0 0'
  },
  theSUValueText: {
    // width: '50%',
    // margin: '0 7px 0 0'
  },
  theSUReferalIcon: {
    width: '10px',
    '& img': {
      width: '100%',
      display: 'block'
    }
  },
  theSUUser: {
    width: '100%'
    // display: 'flex',
    // justifyContent: 'flex-start',
    // alignItems: 'center',
    // width: '90%',
    // margin: '0 0 0 10px'
  },
  theSUUserFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  theSUUFIcon: {
    width: '34px',
    margin: '0 7px 0 0',
    '& img': {
      width: '100%',
      display: 'block'
    }
  },
  theSUUFText: {
    width: '80%'
  },
  theIcon15: {
    width: '15px',
    height: '15px',
    '& img': {
      width: '100%',
      height: '15px'
    }
  },
  theNavLink: {
    padding: '0 0 35px',
    cursor: 'pointer'
  },
  theNavLinkLast: {
    cursor: 'pointer'
  },

  theStickyIcon: {
    position: 'fixed',
    width: '60px',
    height: '60px',
    right: '20px',
    bottom: '20px',
    zIndex: '3',

    '& img': {
      width: '100%'
    }
  },
  theNotiBox: {
    width: '96px',
    height: '96px',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    // position: 'absolute',
    // top: '-5%'
    margin: '-50px auto 0'
  },
  theNoti: {
    width: '80px',
    height: '80px',
    backgroundColor: '#F15A24',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%'
  },
  theIcon50: {
    width: '50px',
    height: '50px',
    '& img': {
      width: '100%',
      height: '50px'
    }
  },
  theUButton: {
    width: '90%',
    margin: '0 auto'
  },

  theModalNoti: {
    width: '100%',
    minHeight: '308px',
    // overflowY: 'scorll',
    backgroundColor: '#FDFDFD',
    position: 'relative',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...md('xs', {
      width: '330px'
    })
  },
  theNotiText: {
    maxHeight: '250px',
    marginBottom: '20px',
    overflowY: 'scroll'
  }
}

export default makeStyles(config)
