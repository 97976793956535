import Routes from './Routes'
import { BrowserRouter } from 'react-router-dom'
import UserContextProvider from './contexts/UserContext'
import toast, { toastConfig } from 'react-simple-toasts'
import 'react-simple-toasts/dist/theme/dark-edge.css'

function App () {
  toastConfig({ theme: 'dark-edge', zIndex: 99999 })
  return (
    <div className='App'>
      <UserContextProvider>
        {/* <BrowserRouter basename={'/bitget'}> */}
        <BrowserRouter>
          <Routes></Routes>
        </BrowserRouter>
      </UserContextProvider>
    </div>
  )
}

export default App
