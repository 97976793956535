import { useTranslation } from 'react-i18next'
// @mui
import { enUS, deDE, idID, zhCN } from '@mui/material/locale'

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS
  },
  {
    label: 'Indonesia',
    value: 'id',
    systemValue: idID
  }
  // {
  //   label: "Chinese",
  //   value: "ch",
  //   systemValue: zhCN,
  //   icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/China_Flag_-_Alternative.svg/1280px-China_Flag_-_Alternative.svg.png",
  // },
]

export default function useLocales () {
  const { i18n, t: translate } = useTranslation()
  const langStorage = localStorage.getItem('i18nextLng')
  const currentLang =
    LANGS.find(_lang => _lang.value === langStorage) || LANGS[1]

  const handleChangeLanguage = newlang => {
    i18n.changeLanguage(newlang)
  }

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS
  }
}
